import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import router from './router';
import store from './store';
import App from './App.vue';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import axios from "axios";
// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

const firebaseConfig = {
	apiKey: "AIzaSyDm7q7jA1LKEy_Cv4GlyWMs5jS2iNqO6wk",
	authDomain: "enigma-b2c97.firebaseapp.com",
	projectId: "enigma-b2c97",
	storageBucket: "enigma-b2c97.appspot.com",
	messagingSenderId: "763041476750",
	appId: "1:763041476750:web:c115ff68d198f3bb2159df"
};

const firebaseApp = initializeApp(firebaseConfig);


Vue.config.productionTip = false;

Vue.prototype.$getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = getAuth(firebaseApp).onAuthStateChanged(user => {
			localStorage.setItem("info", JSON.stringify(user));
			unsubscribe();
			resolve(user);
		}, reject);
	})
};

Vue.prototype.$getUser = () => {
	return JSON.parse(localStorage.getItem("info"));
};

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	var user = await Vue.prototype.$getCurrentUser();
	if (requiresAuth && !user) {
		next({ name: "login" });
	} else {
		next();
	}
});



const api = axios.create({
	baseURL: "http://localhost:70/",
});

api.interceptors.request.use(async config => {
	var token = await getAuth().currentUser.getIdToken()
	config.headers.token = token;
	return config
}, (error) => {
	return Promise.reject(error)
})

Vue.prototype.$axios = api;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");
