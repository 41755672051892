import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/Home.vue'),
			meta: {
				pageTitle: 'Home',
				breadcrumb: [
					{
						text: 'Home',
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		{
			path: '/script/:scriptName',
			name: 'scriptPage',
			component: () => import('@/views/Script/index.vue'),
			meta: {
				pageTitle: "Script Page",
				breadcrumb: [
					{
						text: "Home",
						active: true,
					},
				],
				requiresAuth: true,
			},
		},
		{
			path: "/login",
			name: "login",
			component: () => import("@/views/Auth/Login"),
			meta: {
				layout: "full",
			},
		},
		{
			path: "/register",
			name: "register",
			component: () => import("@/views/Auth/Register"),
			meta: {
				layout: "full",
			},
		},
		{
			path: "/forgot",
			name: "auth-forgot-password",
			component: () => import("@/views/Auth/Forgot"),
			meta: {
				layout: "full",
			},
		},
		{
			path: "*",
			name: "error-404",
			component: () => import("@/views/error/Error404.vue"),
			meta: {
				layout: "full",
			},
		},
	],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

export default router
